/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* .App-link {
  color: #61dafb;
} */

.grid-parent {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px 10px;
}

.artist-search-container {
  height: 6rem;
}

.artist-image {
  aspect-ratio: 1;
  object-fit: cover;
  clip-path: polygon(20% 0%, 0% 20%, 15% 50%, 0% 80%, 20% 100%, 50% 85%, 80% 100%, 100% 80%, 85% 50%, 100% 20%, 80% 0%, 50% 15%);
  transition: clip-path 400ms ease-in-out;
  display: block;
  border-radius: 0.375rem 0.375rem 0 0;
}

.image-container {
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
  position: relative;
  overflow: hidden;
}

.artist-card:hover .artist-image {
  clip-path: polygon(0 0, 0 0, 0 50%, 0 101%, 0 101%, 50% 101%, 101% 101%, 101% 101%, 101% 50%, 101% 0, 101% 0, 50% 0);
}

.artist-card:hover .action-bar {
  right: 0px;
}

.artist-card:hover .popularity-bar {
  left: 0px;
}

.artist-card {
  border-radius: 0.375rem;
  /* border-width: 1px;
  border-color: rgba(0,0,0,0.175);
  border-style: solid; */
  /* border: 1px  solid  rgba(0,0,0,0.175); */
  border: 1px solid rgba(0, 0, 0, 0.175);
}

.artist-name {
  padding: 0.3rem;
  padding-bottom: 0;
}

.artist-info {
  padding: 0.3rem;
  display: flex;
}

.references {
  margin-left: auto;
}

.popularity-bar {
  top: 50%;
  transform: translateY(-50%);
  width: 20%;
  height: 50%;
  position: absolute;
  background-color: white;
  z-index: 2;
  left: -20%;
  /* top: 0; */
  border-radius: 0 30px 30px 0;
  opacity: 90%;
  transition: left 400ms ease-in-out;
  display: flex;
  flex-direction: column;
}

.filter {
  margin-left: auto;
}

.popularity-bar div {
  cursor: default;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
}

.popularity-bar .popularity-up {
  cursor: pointer;
}

.popularity-bar .popularity-down {
  cursor: pointer;
}

.artist-search-input {
  z-index: 5;
  position: relative;
}

.artist-search-image {
  aspect-ratio: 1;
  object-fit: cover;
  height: 100%;
  width: 6rem;
}

.artist-search-results {
  min-width: 30vw;
  border-radius: 0.375rem;
  position: absolute;
  z-index: 5;
  background: rgba(255, 255, 255, 0.93);
  border: 1px solid rgba(0, 0, 0, 0.175);
}

.artist-search-container {
  display: flex;
}

.artist-serach-info {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.blocker {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  content: ' ';
  background: rgba(0,0,0,.5);
  z-index: 4;
}

.artist-search-add-artist {
  cursor: pointer;
  align-self: center;
  margin-left: auto;
  margin-right: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  background: #1fce6c;
  flex-direction: column;
  color: white;
  font-weight: bold;
  font-size: 1.5rem;
  padding-bottom: 0.2rem;
  border-radius: 5rem;
}

.action-bar {
  width: 20%;
  height: 100%;
  position: absolute;
  background-color: white;
  z-index: 2;
  right: -20%;
  top: 0;
  border-radius: 30px 0 0 30px;
  opacity: 90%;
  transition: right 400ms ease-in-out;
  display: flex;
  flex-direction: column;
}

.action-bar div {
  cursor: pointer;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.175);
}

.App {
  max-width: 70vw;
  margin: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}